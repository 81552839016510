<script>
    import Vue from 'vue';
    import BaseVue from '@frontend/Base.vue';
    import Gen from '../helper/Gen';
    Vue.component("Header", () => import ("@frontend/part/Header.vue"))
    export default {
        extends: BaseVue,
        data() {
            return {
                row: {},
                items: [],
                cat: [],
                page404:false,
                catProd:"",
                itemDetail:{},
                phone:"",
            }
        },
        async mounted() {
            await this.ready();
            this.$set(this.$root, 'page', this);
            Vue.component("Header",()=>import("@frontend/part/Header.vue"))
            if (typeof SEMICOLON != "undefined") SEMICOLON.slider.init()
            setTimeout(() => {
                SEMICOLON.documentOnReady.init()
            }, 100)
            $(document).ready(() => {
                AOS.init()
            })
            this.catProd = this.$route.params.slug
            this.apiGetProductList()
            
            $('#detailProd').on('hidden.bs.modal', ()=> {
                this.$router.push('/produk/'+this.$route.params.slug)
                this.refreshMeta()
            });
            if(this.$route.params.detail){
                this.apiGetProductDetail()
            }

        },
        methods: {
            apiGetProductList() {
                Gen.apirest('/product-list/' + this.$route.params.slug, {}, (err, resp) => {
                    if (err) console.log(err)
                    if(resp.code==404)return this.page404=true
                    this.row = resp.data
                    this.items = resp.items
                    this.cat = resp.cat
                    this.phone = resp.phone
                    this.$root.topProgress.done()
                })
            },
            apiGetProductDetail() {
                Gen.apirest('/product-detail-popup/' + this.$route.params.slug+'/'+this.$route.params.detail, {}, (err, resp) => {
                    if (err) console.log(err)
                    this.itemDetail=resp.items;
                    this.viewDetailModal(resp.data,this.itemDetail)
                })
            },
            viewDetail(r,i){
                console.log(i)
                window.history.pushState('product', 'Product', this.$route.params.slug+'/'+i.mpd_slug);
                $("title").text(i.mpd_seo_title)
                $("meta[property='og:image']").attr('content', i.image)
                $("meta[property='og:title']").attr('content', i.mpd_seo_title)
                $("meta[name='description']").attr('content', i.mpd_seo_desc)
                $("meta[property='og:description']").attr('content', i.mpd_seo_desc)
                $("meta[name='keywords']").attr('content', i.mpd_seo_keyword)
                $("meta[name='author']").attr('content', 'Farmsco')
                $("meta[property='og:site_name']").attr('content', 'farmsco.co.id')
                $("meta[property='url']").attr('content', window.location.href)
                $("link[rel='canonical']").attr('content', window.location.href)

            	$('.prod_title').text(r.mp_name)
            	$('.prod_cat').text(i.mpd_name)
            	$('.prod_sub_cat').text(i.mpd_sub_title)
            	$('.prod_desc').html(i.mpd_desc)
            	$('.prod_foto').attr('src',i.image)
            },
            viewDetailModal(r,i){
                $('#detailProd').modal()
                $("title").text(i.mpd_seo_title)
                $("meta[property='og:image']").attr('content', i.image)
                $("meta[property='og:title']").attr('content', i.mpd_seo_title)
                $("meta[name='description']").attr('content', i.mpd_seo_desc)
                $("meta[property='og:description']").attr('content', i.mpd_seo_desc)
                $("meta[name='keywords']").attr('content', i.mpd_seo_keyword)
                $("meta[name='author']").attr('content', 'Farmsco')
                $("meta[property='og:site_name']").attr('content', 'farmsco.co.id')
                $("meta[property='url']").attr('content', window.location.href)
                $("link[rel='canonical']").attr('content', window.location.href)

                $('.prod_title').text(r.mp_name)
                $('.prod_cat').text(i.mpd_name)
                $('.prod_sub_cat').text(i.mpd_sub_title)
                $('.prod_desc').html(i.mpd_desc)
                $('.prod_foto').attr('src',i.image)
            },
            pullOut(id) {
                if (window.innerWidth < 767) {
                    document.getElementById(id).style.bottom = '0';
                }
            },
            pushDown(id) {
                if (window.innerWidth < 767) {
                    document.getElementById(id).style.bottom = '-76%';
                }
            },
            hideMenu(){
                if(!isMobile)return
                document.getElementById('otherProd').style.bottom = '-76%';
            }

        },
        watch: {
            "$route.params.slug" (v) {
                this.catProd = this.$route.params.slug
                this.apiGetProductList()
                if (typeof SEMICOLON != "undefined") SEMICOLON.slider.init()
                setTimeout(() => {
                    SEMICOLON.documentOnReady.init()
                }, 100)
            },
        }
    };
</script>
<template>
    <div Content>
        <Component404 v-if="page404"></Component404>
        <div v-else>
            <Header></Header>
            <section id="slider" class="slider-element slider-parallax swiper_wrapper clearfix sliderActivities">
                <!-- slider-parallax-inner -->
                <div class="paralaxActivities">
                    <div v-if="!isMobile" class="swiper-container swiper-parent">
                        <div class="swiper-wrapper" :style="'background-image: url(\''+uploader(row.mp_image_detail, '1349x350')+'\'); background-size: cover; background-position: top;'">
                            <div class="container clearfix">
                                <div class="slider-caption go-mid prod dark">
                                    <h3>{{ row.mp_name }}</h3>
                                </div>
                            </div>
                            <div class="video-overlay" style="background-color: rgba(0,0,0,0.4);"></div>
                        </div>
                    </div>
                    <div v-else class="swiper-container swiper-parent">
                        <div class="swiper-wrapper" :style="'background-image: url(\''+uploader(row.mp_image_detail, '360x240')+'\'); background-size: cover; background-position: top;'">
                        	<div class="container clearfix">
                                <div class="slider-caption go-mid prod dark">
                                    <h3>{{ row.mp_name }}</h3>
                                </div>
                            </div>
                            <div class="video-overlay" style="background-color: rgba(0,0,0,0.4);"></div>
                        </div>
                    </div>
                    <a href="javascript:;" data-scrollto="#content" data-offset="100" class="dark one-page-arrow"><i class="icon-angle-down infinite animated fadeInDown"></i></a>
                </div>
            </section>
            <section id="page-title" class="page-title-mini">
				<div class="container clearfix">
					<h1 class="d-none d-md-block">&nbsp;</h1>
					<ol class="breadcrumb">
                        <li class="breadcrumb-item"><router-link :to="{name:'index'}">{{ web.mn_home }}</router-link></li>
						<li class="breadcrumb-item"><router-link :to="{name:'ProductList'}">{{ web.mn_just_product }}</router-link></li>
						<li class="breadcrumb-item active" aria-current="page">{{ row.mp_name }} </li>
					</ol>
				</div>
			</section>
            <section id="content" class="overflow-none">
                <div class="content-wrap">
                    <section class="section  nobottommargin notopmargin">
                        <div class="container">
                            <div class="si-sticky">
                                <a target="_blank" :href="'tel:+'+phone" class="social-icon si-rounded si-colored si-youtube" data-animate="bounceInLeft">
                                    <i class="icon-phone3"></i>
                                    <i class="icon-phone3"></i>
                                </a>
                                <a target="_blank" :href="'https://wa.me/'+phone+'?text='+row.question" class="social-icon si-rounded si-colored si-ebay" data-animate="bounceInLeft">
                                    <VImg :src="assets('fo_images','whatsapp.png')" alt="wa" style="width: 25px; margin-top: -10px;" /></VImg>
                                </a>
                            </div>
                            <button class="btn btn-primary float" @click="pullOut('otherProd')">{{ web.lbl_product_lainnya }}</button>
                            <div class="row">
                                <div class="col-md-12">
                                    <div class="wrap_product_intro mb-3" v-html="row.mp_desc"></div>
                                </div>
                            </div>
                            <div class="row justify-content-center">
                                <div class="col-lg-3 col-md-4">
                                    <div class="side_cat_prod" id="otherProd">
    									<h3 @click="pushDown('otherProd')">{{ web.lbl_product_category }}<i class="icon-line-cross float-right d-md-none"></i>
    									</h3>
    									<ul>
    										<li @click="pushDown('otherProd')" v-for="(p,k) in cat">
                                                <router-link :class="catProd==p.mp_slug?'bottom-menu activeMenu': 'bottom-menu'" :to="{name:'Product',params:{slug:p.mp_slug}}">{{ p.mp_name }} 
                                                    <i class="icon-angle-right"></i>
                                                </router-link>
                                            </li>
    									</ul>
    								</div>
                                </div>
                                <div class="col-lg-9 col-md-8">
                                    <div class="row tight" v-if="items.length > 1">
                                        <div class="col-sm-4 col-md-6 col-lg-4 col-6" v-for="(v,k) in items">
                                            <div class="wrap_img_prod_list">
                                                <a href="#detailProd" @click="viewDetail(row,v)" data-toggle="modal" data-target="#detailProd">
                                                    <div v-if="v.mpd_periode_produksi=='Y'" class="ribbon ribbon-top-right"><span>{{ web.lbl_periode_produksi }}</span></div>
                                                    <VImg :src="uploader(v.mpd_image, '262x295')" :alt="v.mpd_name" :title="v.mpd_name"/></VImg>
                                                </a>
                                                <div class="wrap_img_prod_list_content">
                                                    <h3>{{ v.mpd_name }}</h3>
                                                    <p>{{ v.mpd_sub_title }}</p>
                                                    <h4>{{ v.mpd_sort_desc }}</h4>
                                                    <a href="#detailProd" @click="viewDetail(row,v)" data-toggle="modal" data-target="#detailProd">{{ web.btn_detail }} <i class="icon-line-arrow-right"></i></a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div v-else>
        	                            <div class="row tight" v-for="(ve,k) in items">
            								<div class="col-lg-5 col-md-6" >
            									<div class="wrap_prod_pict">
            										<VImg :src="uploader(ve.mpd_image, '262x295')" :alt="ve.mpd_name" :title="ve.mpd_name"/></VImg>
            									</div>
            								</div>
            								<div class="col-md-12 col-lg-7">
            								    <h3>{{ ve.mpd_name }}</h3>
                                                <div v-html="ve.mpd_desc"></div>
            								</div>
            							</div>
        							</div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
                <div class="modal fade" id="detailProd">
        			<div class="modal-dialog modal-lg">
        				<div class="modal-content">
        					<div class="wrap_comp_logo">
        						<VImg :src="assets('fo_images','circle_logo.png')" alt="logo" /></VImg>
        					</div>
        					<div class="modal-header">
        						<h4 class="modal-title prod_title"></h4>
        						<button type="button" class="close" data-dismiss="modal" aria-label="close">
        							<span aria-hidden="true">&times;</span>
        						</button>
        					</div>
        					<div class="modal-body">
        						<div class="row">
        							<div class="col-md-5">
        								<div class="dp-img">
        									<VImg src="images/products-updated/choi-ab.png" alt="Foto Produk" class="prod_foto"></VImg>
        								</div>
        							</div>
        							<div class="col-md-7">
        								<div class="benefit_product">
        									<h3 class="prod_cat"></h3>
        									<p class="prod_sub_cat"></p>
        									<div class="prod_desc"></div>
        								</div>
        							</div>
        						</div>
        					</div>
        				</div>
        			</div>
        		</div>
        	</section>
        </div>
    </div>
</template>